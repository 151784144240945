<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">给教师 【{{tea.NAME}}】 分配权限 </span>
      <div>
        <el-collapse accordion v-model="atm">
          <el-collapse-item  v-for="it in lst_menu" :key="it.ID">
            <template slot="title">
              <b>{{it.MENU_NAME}}</b>
            </template>
            <el-checkbox v-for="c in it.CHILDREN" :key="c.ID" v-model="c.CK">{{c.MENU_NAME}}</el-checkbox>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {mid:[]},
      tea:{},
      lst_role:[],
      lst_menu:[],
      atm:0
    }
  },
  methods:{
    init(t){
      this.sta={show:true,loading:false}
      this.tea=JSON.parse((JSON.stringify(t)));
      this.model.GUID=t.GUID;
      if (this.lst_menu.length==0) this.getTree();
      this.getRel();
    },
    getTree(){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/School/ACL/MenuApi/GetTree",
        completed(its){
          its.DATA.map(o=>{
            o.CHILDREN.map(p=>{p.CK=false})
          })
          self.lst_menu=its.DATA
        }
      })
    },
    getRel(){
      let self=this;
      if (self.lst_menu.length==0){
        setTimeout(self.getRel,300)
        return
      }
      this.whale.remote.getResult({
        url:"/api/School/ORG/TeacherApi/GetRelMenu",
        data:{
          ID:this.tea.ID
        },
        completed(its){
          self.lst_menu.map(o=>{
            if (o.CHILDREN)
              o.CHILDREN.map(p=>p.CK=its.DATA.indexOf(p.ID)>=0)
          })
        }
      })
    },
    submit(){
      let self=this;
      let sel=[]
      self.lst_menu.map(o=>{
        if (o.CHILDREN){
          o.CHILDREN.map(p=>{
            if (p.CK) sel.push(p.ID)
          })
        }
      })
      this.model.MID=sel.join(',')
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/ORG/TeacherApi/UpdateMenu",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
